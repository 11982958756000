import React from 'react';
import path from 'path-browserify';
import classnames from 'classnames';
import { ArrowRight, ArrowLeft, CheckCircle } from 'react-feather';
import compact from 'lodash/compact';
import { Link } from 'gatsby';
import { getImage,GatsbyImage } from 'gatsby-plugin-image';
import {
  CourseFragment,
  LinkComponentFragment,
  StrapiImageFragment,
  PromotionFragment,
  WebsiteLocaleFragment,
  Maybe,
  Strapi_ComponentAtomCourseFeatures,
} from '../../../graphql.schema';
import { ContentBlock } from '../ContentBlock/ContentBlock';
import StrapiButton from '../StrapiLink/StrapiButton';
import Image from '../Image';
import { RichTextField } from '../RichTextField/RichTextField';
import SpeechBubble from '../SpeechBubble';
import { useImageResize } from '../../hooks/useImageResize';
import { WithID, v4tov3base } from '../../lib/mapV4toV3';
import * as styles from './HomepageHero.module.scss';

export type Props = {
  title?: string;
  intro?: string;
  image?: StrapiImageFragment;
  cta?: LinkComponentFragment;
  courses: {
    title?: string;
    intro?: string;
    cta?: LinkComponentFragment;
    courses?: WithID<CourseFragment, 'string'>[];
  };
  minThumbnails?: number;
  courseSlugBase: string;
  promotionSlugBase: string;
  promotion?: PromotionFragment;
  websiteLocale?: WebsiteLocaleFragment;
  heroIntro: {
    hero_title?: string;
    hero_cta?: LinkComponentFragment;
    hero_full_image?: StrapiImageFragment;
    heroBodyFeature?: Maybe<Maybe<Pick<Strapi_ComponentAtomCourseFeatures, 'feature'>>[]>;
    show_hero_section?: boolean;
    promotion?: PromotionFragment;
  };
  showFormInHeader?: boolean;
  inlineHubspotForm: {
    title?: string;
    description?: string;
    html?: string;
  };
};

export const HomepageHero = ({
  minThumbnails = 2,
  image,
  title,
  intro,
  cta,
  courses,
  courseSlugBase,
  promotion,
  promotionSlugBase,
  websiteLocale,
  heroIntro,
  showFormInHeader,
  inlineHubspotForm
}: Props) => {
  const { imageWrapper } = useImageResize(image);
  const url = `${promotionSlugBase}/${promotion?.slug}`;
  const { hasRightToLeftLanguage } = v4tov3base({ ...websiteLocale })
  return (
    <div className={classnames('HomepageHero-root', styles.root)}>
      {heroIntro?.show_hero_section ?
        <div className={classnames('HomepageHero-hero', styles.heroSection)}>
          <div ref={imageWrapper} className={styles.heroimageContainer}>
            {heroIntro?.hero_full_image && (
           <img
              src={heroIntro?.hero_full_image?.url}
              className={classnames('HomepageHero-hero-imageContainer', styles.image)}
              loading="eager"
              width={heroIntro?.hero_full_image?.width ?? undefined}
              height={heroIntro?.hero_full_image?.height ?? undefined}
              alt={heroIntro?.hero_full_image?.alternativeText ?? heroIntro?.hero_full_image?.caption ?? ''}
            />
            )
            }
          </div>
          <div className={classnames(styles.heroContent, heroIntro?.promotion?.id && styles.herotextContent)}>
            {heroIntro?.promotion?.id && (
              <SpeechBubble
                className={websiteLocale?.hasRightToLeftLanguage ? styles.promotionRtl : styles.promotion}
                speechBubbleText={heroIntro?.promotion?.title ?? ''}
                linkText={heroIntro?.promotion?.homepageLinkText ?? ''}
                url={url}
                isReverse={websiteLocale?.hasRightToLeftLanguage}
              />
            )}
            <ContentBlock
              isFluid
              title={heroIntro.hero_title}
              customRender={
                <ul>
                  {compact(heroIntro.heroBodyFeature).map(({ feature }, i) => (
                    <li
                      className={classnames(styles.listItem, 'text-2')}
                      key={`${feature}-${i}`}
                    >
                      <div className={classnames(styles.checkListItem)}>
                        <CheckCircle
                          size={20}
                          className={classnames(styles.check, 'ml-1 has-text-weight-bold')}
                        />{' '}
                      </div>
                      <div className={classnames(styles.listItemSpace)}>{feature}</div>
                    </li>
                  ))}
                </ul>
              }
              button={heroIntro.hero_cta}
              titleOverride={<h1 className={classnames('title is-1', styles.heroTitle)}>{heroIntro.hero_title}</h1>}
            />
          </div>
        </div>
        :
        <div className={classnames('HomepageHero-hero', showFormInHeader ? styles.heroHeaderForm : styles.hero)}>
          <div ref={imageWrapper} className={classnames('HomepageHero-hero-imageContainer', styles.imageContainer)}>
            {image ? (
             <Image
                 image={image}
                 className={classnames('HomepageHero-hero-image', styles.image)}
                 loading="eager"
                 width={image?.formats?.medium?.width ?? undefined}
                 height={image?.formats?.medium?.height ?? undefined}
               />
            ) : null
            }
          </div>
          <div className={classnames('HomepageHero-hero-content ', promotion?.id ? styles.speechBubbleText : styles.content)}>
            {/* TODO: Connect promotions to homepage */}
            {promotion?.id && (
              <SpeechBubble
                className={websiteLocale?.hasRightToLeftLanguage ? styles.promotionRtl : styles.promotion}
                speechBubbleText={promotion?.title ?? ''}
                linkText={promotion?.homepageLinkText ?? ''}
                url={url}
                isReverse={websiteLocale?.hasRightToLeftLanguage}
              />
            )}
            <ContentBlock
              isFluid
              title={title}
              content={intro}
              button={cta}
              titleOverride={<h1 className={classnames('title is-1', styles.heroTitle)}>{title}</h1>}
              showFormInHeader={showFormInHeader}
              inlineHubspotForm={inlineHubspotForm}
            />
          </div>
        </div>
      }
      <div className={classnames('HomepageHero-courses', styles.courses)}>
        <div className={classnames(styles.heading)}>
          <h2 className={classnames('title is-title-2', styles.title)}>{courses.title}</h2>
        </div>
        <div className={classnames('HomepageHero-courses-thumbnails', styles.thumbnails)}>
          {compact(courses?.courses).length >= minThumbnails
            ? compact(courses?.courses).map((course) => {
              return course.featured ? (
                <Link
                  key={`homepagehero-course-${course.id}`}
                  className={styles.thumbnail}
                  to={path.join(courseSlugBase, course.slug)}
                >
                  <div className={styles.thumbnailContent}>
                    <h4 className={classnames('is-title-4', styles.thumbnailTitle)}>
                      {course.name}
                      {hasRightToLeftLanguage ? <ArrowLeft className={styles.thumbnailArrow} size={16} /> : <ArrowRight className={styles.thumbnailArrow} size={16} />}
                    </h4>
                  </div>
                  <img
                    alt={course.image?.alternativeText ?? image?.caption ?? ''}
                    className={styles.thumbnailImage}
                    src={course.image?.formats.small?.url}
                    height={course.image?.formats.small?.height}
                    width={course.image?.formats.small?.width}
                  />
                </Link>
              ) : null
            })
            : null}
        </div>
        <div className={styles.text}>
          <RichTextField className={classnames('HomepageHero-courses-intro', styles.intro)} content={courses?.intro} />
          {courses.cta ? (
            <div className={classnames('HomepageHero-courses-button', styles.button)}>
              <StrapiButton {...courses.cta} type="secondary" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
